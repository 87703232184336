<template>
  <div>
    <!-- 标题 -->
    <!-- <div>
      <van-nav-bar
        title="社区活动"
      />
    </div> -->
    <!-- tab栏 -->
    <div class="information">
      <!-- 全部 -->
      <div class="center"
        v-for="item in list"
        :key="item.index">
        <router-link :to="'/activity/detail/' + item.AId">
          <div class="centerTitle van-ellipsis">{{ item.Title }}</div>
          <div class="Content">
            <div style="display: inline-block"
              class="ContentA">
              <img :src="item.AImgUrl"
                alt="" />
            </div>
            <div style="display: inline-block"
              class="ContentB">
              <span class="van-multi-ellipsis--l3">{{ item.Outline }}</span>
              <br />
              <span class="centertime">{{ item.ADate }}</span>
            </div>
          </div>
        </router-link>
      </div>
      <!-- 没有更多数据了 -->
      <div v-if="hidden"
        class="hidden">───── 没有更多数据了 ─────</div>
    </div>
  </div>
</template>
<script>
import { setOpenId, getOpenId } from "@/utils/auth";
import { WeGetMyActivityPages } from "@/api/RealInfo";
export default {
  data () {
    return {
      akId: 0, //tab栏
      list: {}, //资讯列表
      listfrom: {}, //查询传参
      hidden: true, //没有更多数据了
    };
  },
  methods: {
    // 获取分页列表
    getList () {
      WeGetActivityPage({ page: 1, limit: 99, akId: 0 })
        .then((res) => {
          if (res.data.code == 0) {
            if (res.data.count == 0) {
              this.list = {};
            } else {
              this.list = res.data.data;
              for (var i = 0; i < this.list.length; i++) {
                if (this.list[i].AImgUrl == "") {
                  this.list[i].AImgUrl = "http://lccgzapi.zhsq.cloud//upload/images/2023/3/81ce8aa3-48b.jpeg";
                }
              }
            }
          }
        })
        .catch(() => { });
    },
    // tan点击切换事件
    onClick (e) {
      this.akId = e;
      this.getList();
    },
  },
  mounted () {
    if (this.$route.query["openid"]) {
      setOpenId(this.$route.query["openid"]);
    }
    this.getList();
  },
};
</script>
<style>
.hidden {
  text-align: center;
  font-size: 12px;
  color: #ccc;
  margin: 10px 0px 25px 0px;
}
</style>
